import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import formBgSmall from '../../images/bg-blue.svg';

export const MainBackgroundImage = ({ children, heroImage }) => {
    return (
        <StyledBackgroundWrapper>
            <GatsbyImage image={heroImage} className="main-background" alt="" />
            <img src={formBgSmall} className="main-background-small" alt="" />
            {children}
        </StyledBackgroundWrapper>
    );
};

const StyledBackgroundWrapper = styled.div`
    position: relative;

    .main-background {
        display: none;
    }

    @media (min-width: 992px) {
        .main-background {
            display: block;
            min-height: 500px;
        }
    }

    @media (min-width: 1280px) {
        .main-background {
            min-height: 480px;
        }
    }

    .main-background-small {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media (min-width: 992px) {
        .main-background-small {
            display: none;
        }
    }

    .main-hero-small {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 90%;
    }

    @media (min-width: 992px) {
        .main-hero-small {
            display: none;
        }
    }
`;
