import React from 'react';

import { Main } from '../components/MainBlock';
import { HowTo } from '../components/HowToBlock';
import { Reviews } from '../components/ReviewsBlock';
import { Faq } from '../components/FaqBlock';

export const renderBlock = (block) => {
    switch (block.fieldGroupName) {
        case 'Page_Components_Component_Main':
            return <Main data={block} />;
        case 'Page_Components_Component_HowTo':
            return <HowTo data={block} />;
        case 'Page_Components_Component_Reviews':
            return <Reviews data={block} />;
        case 'Page_Components_Component_Faq':
            return <Faq data={block} />;
        default:
            return null;
    }
};
