import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import graduation from '../../images/howto-graduation.svg';
import saveMoney from '../../images/howto-save-money.svg';
import contract from '../../images/howto-contract.svg';

import arrow from '../../images/arrow.svg';

export const HowTo = ({ data }) => {
    const images = [graduation, saveMoney, contract];
    const { title, listTitle, list } = data;

    return (
        <StyledHowToBlock className="howto">
            <Container>
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                <p className="howto__subtitle" dangerouslySetInnerHTML={{ __html: listTitle }} />
                {list &&
                    list.map((item, key) => (
                        <Row className="howto__item no-gutters" key={key}>
                            <Col xs="auto" className="howto__item-image">
                                <img src={images[key]} alt="" />
                            </Col>
                            <Col className="howto__item-content">
                                <h3 dangerouslySetInnerHTML={{ __html: item.listItemTitle }} />
                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                            </Col>
                        </Row>
                    ))}
            </Container>
        </StyledHowToBlock>
    );
};

const StyledHowToBlock = styled.div`
    counter-reset: item;

    .container {
        padding-top: 10px;
        padding-bottom: 100px;
        max-width: calc(767px + 30px);

        @media (min-width: 768px) {
            padding-top: 50px;
        }
    }

    h2 {
        color: #4c4c4c;
        font-size: 28px;
        font-weight: 400;
        margin: 0;

        @media (min-width: 768px) {
            font-size: 50px;
        }
    }

    .howto__subtitle {
        color: #378aff;
        font-size: 16px;
        font-weight: 700;
        margin-top: 10px;
        opacity: 0.8;
        position: relative;

        @media (min-width: 768px) {
            font-size: 28px;
            margin-left: 30px;
            padding-left: 20px;
            margin-top: 20px;
        }
    }

    .howto__subtitle::before {
        @media (min-width: 768px) {
            background: url(${arrow}) no-repeat center;
            content: '';
            height: 17px;
            left: 0;
            position: absolute;
            top: 12px;
            width: 10px;
        }
    }

    .howto__item {
        counter-increment: item;
        display: block;
        text-align: center;
        max-width: 310px;
        margin: 50px auto 0;

        @media (min-width: 490px) {
            display: flex;
            text-align: left;
            margin: 50px 0 0;
            max-width: none;
        }
    }

    .howto__item-image {
        @media (min-width: 490px) {
            padding: 0 20px 0 0;
        }

        @media (min-width: 768px) {
            padding: 0 55px;
        }
    }

    .howto__item h3 {
        color: #4c4c4c;
        font-size: 16px;
        font-weight: bold;
        opacity: 0.8;

        @media (min-width: 768px) {
            font-size: 19px;
        }

        &::before {
            content: counter(item) '. ';
        }
    }

    .howto__item p,
    .howto__item ul {
        color: #4c4c4c;
        font-size: 14px;
        opacity: 0.8;
        margin-bottom: 0.5rem;

        @media (min-width: 768px) {
            font-size: 17px;
        }
    }

    .howto__item ul {
        list-style: none;
        padding-left: 0;
    }

    .howto__item li::before {
        content: '· ';
        font-weight: bold;
    }

    .howto__item li + li {
        margin-top: 10px;
    }

    .howto__item *:last-child {
        margin-bottom: 0;
    }

    .howto__item + .howto__item {
        margin-top: 50px;
    }

    .howto__item-content {
        margin-top: 10px;

        @media (min-width: 490px) {
            margin-top: 0;
        }
    }
`;

export const query = graphql`
    fragment HowToBlock on WpPage_Components_Component_HowTo {
        fieldGroupName
        title
        listTitle
        list {
            description
            listItemTitle
        }
    }
`;
