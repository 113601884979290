// libraries
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
// static
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import stars from '../../../images/stars.svg';

export const ReviewsSlider = ({ items }) => {
    const [isReady, setIsReady] = useState(false);
    const settings = {
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: '145px',
        arrows: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    centerMode: true,
                    centerPadding: '100px',
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    centerMode: true,
                    centerPadding: '145px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    centerMode: true,
                    centerPadding: '45px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: true,
                    centerPadding: '20px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    useEffect(() => {
        setIsReady(true);
    }, [isReady]);

    return isReady ? (
        <StyledReviewsSlider>
            <Slider {...settings}>
                {items &&
                    items.map((item, key) => (
                        <div className="slide" key={key}>
                            <div className="slide__heading d-flex">
                                <div className="slide__photo">
                                    <GatsbyImage image={item.photo.localFile?.childImageSharp.gatsbyImageData} alt="" />
                                </div>
                                <div className="slide__name">
                                    <h3>{item.name}</h3>
                                    <div
                                        className="rating"
                                        style={{
                                            backgroundSize: (100 / 5) * item.rating + '% 100%',
                                        }}
                                        aria-label={item.rating + ' stars'}
                                    />
                                </div>
                            </div>
                            <div className="slider__review">{item.review}</div>
                        </div>
                    ))}
            </Slider>
        </StyledReviewsSlider>
    ) : null;
};

const StyledReviewsSlider = styled.div`
    margin-top: 20px;

    .slide {
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
        padding: 30px;
        @media (max-width: 340px) {
            padding: 30px 20px;
        }
    }

    .gatsby-image-wrapper {
        border-radius: 50%;
        box-shadow: 0 0 0 6px #ccc;
    }

    .slide__name {
        margin-left: 20px;
    }

    .slide__name h3 {
        color: #4c4c4c;
        font-size: 20px;
        font-weight: 700;
    }

    .rating {
        background-image: linear-gradient(to right, #ffa600 0%, #ffa600 100%);
        background-position: left;
        background-repeat: no-repeat;
        background-size: 80% 100%;
        height: 16px;
        position: relative;
        width: 140px;
    }

    .rating::before {
        background-image: url(${stars});
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .slider__review {
        color: #4c4c4c;
        font-size: 16px;
        margin-top: 25px;
        opacity: 0.8;
    }

    // Slick
    .slick-list {
        @media (max-width: 767px) {
            padding: 0 40px !important;
        }
        @media (max-width: 479px) {
            padding: 0 20px !important;
        }
    }

    .slick-track {
        padding-bottom: 10px;
        padding-top: 10px;
        display: flex;
    }

    .slick-slide {
        height: auto;
        transform: scale(0.85) translateX(40px);
        transition: transform 0.15s ease;
        margin: 0 10px;

        & > div {
            height: 100%;

            & > div {
                height: 100%;
            }
        }
    }

    .slick-active {
        transform: scale(0.9);
    }

    .slick-current {
        transform: scale(1);
    }

    .slick-active ~ .slick-slide:not(.slick-active) {
        transform: scale(0.85) translateX(-40px);
    }

`;
