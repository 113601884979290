import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { Container, Accordion, Card, AccordionContext, useAccordionToggle } from 'react-bootstrap';
import styled from 'styled-components';

import arrow from '../../images/arrow.svg';

export const Faq = ({ data }) => {
    const { title, info, questions } = data;

    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <button type="button" className={isCurrentEventKey ? 'active' : ''} onClick={decoratedOnClick}>
                {children}
            </button>
        );
    }

    return (
        <StyledFaqBlock>
            <Container>
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                <Accordion>
                    {questions &&
                        questions.map((item, key) => (
                            <Card key={key + 1}>
                                <Card.Header className={key + 1}>
                                    <ContextAwareToggle eventKey={key + 1}>{item.question}</ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={key + 1}>
                                    <Card.Body dangerouslySetInnerHTML={{ __html: item.answer }} />
                                </Accordion.Collapse>
                            </Card>
                        ))}
                </Accordion>
                <div className="info" dangerouslySetInnerHTML={{ __html: info }} />
            </Container>
        </StyledFaqBlock>
    );
};

const StyledFaqBlock = styled.div`
    .container {
        padding-top: 50px;
        padding-bottom: 50px;

        @media (min-width: 768px) {
            padding-top: 100px;
        }
    }

    h2 {
        color: rgba(55, 138, 255, 0.8);
        font-size: 20px;
        font-weight: 700;

        @media (min-width: 768px) {
            font-size: 28px;
        }
    }

    .accordion {
        margin-top: 20px;

        @media (min-width: 768px) {
            margin-top: 50px;
        }
    }

    .card {
        border: none;
        border-radius: 0;
    }

    .card-header {
        background: transparent;
        padding: 0;
        border: none;

        button {
            position: relative;
            background: transparent;
            border: none;
            padding: 0 0 0 22px;
            color: rgba(76, 76, 76, 0.8);
            font-size: 16px;
            font-weight: 700;
            text-align: left;

            @media (min-width: 768px) {
                color: #4c4c4c;
                font-size: 20px;
            }

            &:hover,
            &:focus {
                outline: transparent;
            }

            &::before {
                background: url(${arrow}) no-repeat center/contain;
                content: '';
                height: 14px;
                left: 2px;
                position: absolute;
                top: 5px;
                transform: rotate(0deg);
                width: 8px;

                @media (min-width: 768px) {
                    top: 10px;
                }
            }

            &.active::before {
                transform: rotate(90deg);
            }
        }
    }

    .card-body {
        padding: 5px 0 0 22px;
        color: #4c4c4c;
        font-size: 14px;

        @media (min-width: 768px) {
            padding: 15px 0 0 22px;
            color: rgb(76, 76, 76, 0.8);
            font-size: 18px;
        }

        p,
        ul {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            padding-left: 0;
            list-style: none;
        }

        li::before {
            content: '· ';
            font-weight: bold;
        }

        li + li {
            margin-top: 10px;
        }
    }

    .card + .card {
        margin-top: 10px;

        @media (min-width: 768px) {
            margin-top: 30px;
        }
    }

    .info {
        padding: 30px 18px 0;
        border-top: 1px solid #d3d2d2;
        margin-top: 50px;
        color: rgba(76, 76, 76, 0.8);
        font-size: 12px;

        @media (min-width: 768px) {
            padding: 40px 0 0;
            font-size: 14px;
        }
    }
`;

export const query = graphql`
    fragment FaqBlock on WpPage_Components_Component_Faq {
        fieldGroupName
        title
        questions {
            answer
            question
        }
        info
    }
`;
