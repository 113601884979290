import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import { ReviewsSlider } from './Slider';

export const Reviews = ({ data }) => {
    const { title, list } = data;

    return (
        <StyledReviewsBlock className="reviews">
            <Container>
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
            </Container>
            <ReviewsSlider items={list} />
        </StyledReviewsBlock>
    );
};

const StyledReviewsBlock = styled.div`
    background: #f2f6fc;
    padding-bottom: 50px;
    padding-top: 40px;

    h2 {
        color: #4c4c4c;
        font-size: 28px;
        font-weight: 700;
        text-align: center;

        span {
            font-weight: 400;
        }
    }
`;

export const query = graphql`
    fragment ReviewsBlock on WpPage_Components_Component_Reviews {
        title
        fieldGroupName
        list {
            name
            rating
            review
            photo {
                localFile {
                    childImageSharp {
                        gatsbyImageData(quality: 100)
                    }
                }
            }
        }
    }
`;
