import Select, { components } from 'react-select';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateValue } from '../../../store/actions/form';

import { TERM_OPTIONS } from '../../../settings';

//images
import triangle from '../../../images/triangle.svg';

const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        {children} <span>días</span>
    </components.SingleValue>
);

const DropdownIndicator = ({ children, ...props }) => (
    <components.DropdownIndicator {...props}>
        <img src={triangle} alt="" />
    </components.DropdownIndicator>
);

const IndicatorSeparator = () => {
    return null;
};

const Option = ({ children, ...props }) => {
    return (
        <components.Option {...props}>
            {children} <span>días</span>
        </components.Option>
    );
};

const customStyles = {
    control: (provided) => ({
        ...provided,
        borderRadius: 15,
        border: '1px solid #ff9d00',
        cursor: 'pointer',
        '&:hover': {
            borderColor: '#ff9d00',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '12px 8px',
    }),
    singleValue: (provided) => ({
        ...provided,
        width: '100%',
        textAlign: 'center',
        fontSize: 26,
        fontWeight: 700,
        color: '#ff9d00',
        '& span': {
            fontWeight: 400,
        },
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100%',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: '8px 26px 8px 8px',
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: 15,
    }),
    menuList: (provided) => ({
        ...provided,
        borderRadius: 15,
        padding: 0,
    }),
    option: (provided, { isFocused, isSelected }) => ({
        ...provided,
        background: isSelected ? '#ff9d00' : isFocused ? '#ffebcc' : '#ffffff',
        textAlign: 'center',
        fontSize: 26,
        fontWeight: 700,
        color: isSelected ? '#ffffff' : isFocused ? '#ff9d00' : '#ff9d00',
        '& span': {
            fontWeight: 400,
        },
    }),
};

export const SelectTerm = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.form);
    const termVal = data.termDefault;
    const currentItem = TERM_OPTIONS.filter((item) => item.value === termVal)[0];

    useEffect(() => {
        updateValue(dispatch, { term: data.termDefault });
    }, []);

    const handleChange = (selectedOption) => {
        updateValue(dispatch, { term: selectedOption.value });
    };

    return currentItem ? (
        <>
            <label htmlFor="term">¿Para cuánto tiempo?</label>
            <Select
                className="term"
                onChange={handleChange}
                options={TERM_OPTIONS}
                defaultValue={currentItem}
                components={{
                    SingleValue,
                    DropdownIndicator,
                    IndicatorSeparator,
                    Option,
                }}
                styles={customStyles}
                isSearchable={false}
            />
        </>
    ) : null;
};
