import React from 'react';
import { navigate, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { MainBackgroundImage } from '../BackgroundImage';
import { SelectAmount } from './SelectAmount';
import { SelectTerm } from './SelectTerm';

import heroSmall from '../../images/hero-small.png';

export const Main = ({ data }) => {
    const { title } = data;
    const heroImage = data.background.localFile?.childImageSharp.gatsbyImageData;
    const gaEvent = () => {
        typeof window !== 'undefined' && window.gtag && window.gtag('event', 'takeloanclick');
    };

    return (
        <MainBackgroundImage heroImage={heroImage}>
            <StyledMainBlock>
                <Container>
                    <Row className="justify-content-between flex-lg-nowrap">
                        <Col lg="4" className="title">
                            <div className="title__wrapper">
                                <img src={heroSmall} className="main-hero-small" alt="heroImage" />
                                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                            </div>
                        </Col>
                        <Col lg="auto" className="form">
                            <form
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    navigate('/registration/');
                                }}
                            >
                                <div className="form-group">
                                    <SelectAmount />
                                </div>
                                <div className="form-group">
                                    <SelectTerm />
                                </div>
                                <button className="btn btn-primary" onClick={gaEvent}>
                                    Pedir préstamo&nbsp;&gt;
                                </button>
                            </form>
                            <div className="form__info">
                                Ofrecemos préstamos personales, que se pueden devolver de forma flexible entre 91 y 120
                                días con una TAE mínima de 0% y máxima de 3112.64%. Por ejemplo, para un préstamo de
                                300€, con devolución en 91 días, el coste adicional sería de 259,35€, y por lo tanto el
                                coste total a devolver ascenderá a 559,35€ Con una TAE del 1116,81%. Goprestamo no
                                cobrará por usar nuestros servicios. El costo exacto de cada préstamo depende de cada
                                solicitud individual. Siempre se va a recibir información completa sobre la TAE exacta y
                                todas las tarifas antes de firmar el contrato de préstamo.
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledMainBlock>
        </MainBackgroundImage>
    );
};

const StyledMainBlock = styled.div`
    padding-bottom: 60px;
    @media (min-width: 585px) {
        padding-bottom: 100px;
    }
    @media (min-width: 992px) {
        padding-bottom: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    .container {
        @media (max-width: 991px) {
            max-width: none;
        }

        @media (min-width: 992px) {
            padding-top: 50px;
        }
    }

    .title {
        background: #f2f6fc;
        @media (min-width: 992px) {
            background: transparent;
        }
        @media (min-width: 1280px) {
            margin-left: -60px;
        }

        &__wrapper {
            @media (max-width: 991px) {
                max-width: 690px;
                padding: 30px 0;
                margin: 0 auto;
                position: relative;
            }
        }

        h2 {
            color: #378aff;
            font-size: 6vw;
            font-weight: 700;
            line-height: 1.4;
            margin: 0 0 0 auto;
            width: 55%;
            @media (min-width: 585px) {
                font-size: 35px;
            }
            @media (min-width: 992px) {
                color: #fff;
                margin: 0;
                width: auto;
            }
        }
    }

    .form {
        @media (max-width: 991px) {
            max-width: 720px;
            margin: 0 auto;
            padding-top: 20px;
        }
        @media (min-width: 992px) {
            max-width: 380px;
        }
        @media (min-width: 1280px) {
            flex-shrink: 1;
            max-width: 490px;
            margin-right: -60px;
        }
    }

    form {
        margin: 0 auto;
        @media (min-width: 1280px) {
            max-width: 350px;
        }
        .form-group {
            margin-bottom: 10px;
        }

        label {
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.2;
            margin-bottom: 10px;
            text-align: center;
            width: 100%;
        }

        .btn-primary {
            width: 100%;
            background-color: #ff9d00;
            border-radius: 15px;
            border: none;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            display: block;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 2px;
            margin-top: 20px;
            padding: 11px 7px;
            text-transform: uppercase;
        }

        .btn-primary:hover,
        .btn-primary:focus {
            background-color: #e68d00;
        }

        .btn-primary:not(:disabled):not(.disabled):active {
            background-color: #ff9d00;
        }

        .btn-primary:not(:disabled):not(.disabled):active:focus {
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
    }
    .form__info {
        display: none;
        margin: 20px 0 0;
        color: #fff;
        font-size: 10px;
        @media (min-width: 992px) {
            display: block;
        }
        @media (min-width: 1280px) {
            margin: 20px 0 0 20px;
        }
    }
`;

export const query = graphql`
    fragment MainBlock on WpPage_Components_Component_Main {
        fieldGroupName
        title
        background {
            localFile {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
        }
    }
`;
